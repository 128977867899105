import { defineStore } from 'pinia';

export const useBookingStore = defineStore('booking', () => {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const bookingId = ref<number>(0); // идентификатор заявки
    const currentStep = ref<number>(0); // текущий шаг приемки
    const bookingSessionEnd = ref<Date | null>(null); // время до окончания приемки
    // массив шагов
    const steps = ref<string[] >([
        t('booking.client.purchase_conditions.title'),
        t('booking.client.personal_data.title'),
        t('booking.client.offer.title'),
        t('booking.client.payment.title'),
    ]);

    const tooltips = ref<string[]>([
        t('booking.client.purchase_conditions.tooltip'),
        t('booking.client.personal_data.tooltip'),
        t('booking.client.offer.tooltip'),
        t('booking.client.payment.tooltip'),
    ]);
    /** Переход на следующий шаг */
    const goToNextStep = () => {
        if (currentStep.value < steps.value.length - 1) {
            currentStep.value++;
            updateRoute(currentStep.value);
        }
    };

    /**
    * Пушит в адресную строку текущий шаг
    * @param step Текущий шаг
    * @param payment Необязательный параметр; true, если оплата успешная
    */
    const updateRoute = (step: number, payment?: boolean) => {
        router.push({
            name: route.name,
            params: { id: bookingId.value },
            query: { step: getStepTitle(step, payment) }
        });
    };

    /**
    * Преобразует число текущего шага в текстовый формат
    * @param step Текущий шаг
    * @param payment Необязательный параметр; true, если оплата успешная
    */
    const getStepTitle = (step: number, payment?: boolean) => {
        switch (step) {
        case 0:
            return 'conditions';
        case 1:
            return 'personal';
        case 2:
            return 'offer';
        case 3:
            return 'payment';
        case 4:
            return payment ? 'success' : 'fail';
        default:
            return '';
        }
    };

    /**
    * Преобразует текстовый формат значения счетчика шагов в числовой
    * @param stepName Текущий шаг
    * @returns Номер текущего шага бронирования
    */
    const getCurrentStepNumber = (stepName: string) : number => {
        switch (stepName) {
        case 'personal':
            return 1;
        case 'offer':
            return 2;
        case 'payment':
            return 3;
        case 'success':
        case 'fail':
            return 4;
        case 'conditions':
        default:
            return 0;
        }
    };

    return {
        bookingId,
        currentStep,
        goToNextStep,
        steps,
        tooltips,
        bookingSessionEnd,
        updateRoute,
        getCurrentStepNumber,
    };
});
